<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo remito recibido"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
               
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="cotizaciones"          
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} remitos"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Remitos recibidos</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column
            field="id"
            header="#"
            :sortable="true"
            headerStyle="width:5%; min-width:7rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">#</span>
              {{ slotProps.data.id }}
            </template>
        </Column>

          <Column
            field="date"
            header="Fecha"
            :sortable="true"
            headerStyle="width:10%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{ slotProps.data.fecha_ingreso }}
            </template>
        </Column>

          <Column
            field="oc_number"
            header="N° OC"
            :sortable="true"
            headerStyle="width:10%; min-width:7rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">N° OC</span>
              {{ slotProps.data.oc_number }}
            </template>
        </Column>

          <Column
            field="provider_name"
            header="Proveedor"
            :sortable="true"
            headerStyle="width:20%; min-width:4rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Proveedor</span>
              {{ slotProps.data.provider_name}} 
            </template>
        </Column>
        <Column
            field="observacion"
            header="Detalle"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Detalle</span>
              {{ slotProps.data.obs }}
            </template>
        </Column>
            <Column
                field="status"
                header="Estado"
                :sortable="true"
                headerStyle="min-width:10rem;"
                >
                <template #body="slotProps">
                  <div class="flex align-content-center ">

                  </div>
                </template>
            </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-primary mr-2"
                @click="showPdf(slotProps.data.id)"
              />
              <Button
                icon="pi pi-file-pdf"
                class="p-button-rounded p-button-danger mr-2"
                @click="downloadPdf(slotProps.data.id)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              > Eliminaras el remito N° <b> {{ product.id }} </b>
              ?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "./../../services/crud.service";

export default {
  data() {
    return {
      routeName: null,
      cotizaciones: [
       
      ],
      sendMailDialog: false,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      providers: []
    };
  },
   
  created() {
    // get route name
    this.routeName = this.$route.name;
     
    this.initFilters();
  },
  mounted() {
     CrudService.getCRUD('api/remitosr').then(
       (data) => (this.cotizaciones = data)
    ).catch(
      (error) => console.log(error)
    );
    // CrudService.getCRUD('api/clients').then(
    //   (data) => (this.providers = data)
    // );

  },
  methods: {
    sendMailConfirm() {
      // confirm dialog

      alert('enviar mail')



    },
    accept(budget){
      console.log(budget.id)
      CrudService.updateCRUD('api','cotizacion/accept', budget).then(
        (data) => {
          const index = this.cotizaciones.findIndex(
            (item) => item.id === budget.id
          );

          // update budget in array
          this.cotizaciones[index] = data.data;
        }
      ).catch(
        (error) => console.log(error)
      );
    
    },
    reject(budget){
      CrudService.updateCRUD('api','cotizacion/reject', budget).then(
        (data) => {
          // find index of budget in array
          const index = this.cotizaciones.findIndex(
            (item) => item.id === budget.id
          );

          // update budget in array
          this.cotizaciones[index] = data.data;
        }
      ).catch(
        (error) => console.log(error)
      );
    
    },
    
    statusName(value){

      // convert value to integer
      value = parseInt(value);

      switch (value) {

        case 0:
          return 'Pendiente';
          break;
        case 1:
          return 'Enviada';
          break;
        case 2:
          return 'Vencida';
          break;
        case 3:
          return 'Aprobada';
          break;
        case 4:
          return 'Rechazada';
          break;
        default:
          return 'Pendiente';
          break;
        }
    },
    giveMeName(id) {
      let exist = this.providers.find(provider => provider.id === Number(id));

      if(!exist) {
        return ' - ';
      }else {
        return exist.name;
      }
    },
    afipType(value) {
      if (value == 1) {
        return "Responsable Inscripto";
      }
      if (value == 2) {
        return "Monotributista";
      }
      if (value == 3) {
        return "Exento";
      }
      if (value == 4) {
        return "Consumidor Final";
      }

      return " - ";
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      // go to route cotizaciones/create
      this.$router.push({ name: "CreateRemito" });
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          

          CrudService.updateCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          this.product.quantity =1
          CrudService.createCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    editProduct(product) {
      // this.product = { ...product };
      // this.productDialog = true;

      alert('en desarrollo')
    },
    showPdf(id) { 

      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      let url = this.appBackend + `/api/cotizacion/${id}/show`;

      // new window
      anchor.target = "_blank";
      anchor.href = url;

      // click
      anchor.click();



    },
    downloadPdf(id) { 

      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      let file = this.appBackend + `/api/remitos_r/${id}/pdf`;

      let headers = new Headers();
      headers.append('Authorization', 'Bearer ' + localStorage.getItem("api_token")['api_token']);

      fetch(file, { headers })
          .then(response => response.blob())
          .then(blobby => {
              let objectUrl = window.URL.createObjectURL(blobby);

              anchor.href = objectUrl;
              anchor.download =  'C_' + id + '_MF.pdf';
              anchor.click();

              window.URL.revokeObjectURL(objectUrl);
          });


    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() { 

      CrudService.deleteCRUD('api', 'cotizacion', this.product.id).then(
        (data) => {
          
          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.cotizaciones = this.cotizaciones.filter((val) => val.id !== this.product.id);

      this.deleteProductDialog = false;
      this.product = {};

    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

 
<style scoped>
        .status_box {
          display: inline-block;
          line-height: 2rem;
              width: 100%;
          font-weight: bolder;
          text-align: center;
          padding: 5px;
          text-transform: uppercase;
        }
        .status-4 {
            border: 1px solid #74171171;
            background-color: #cc050571;
        }
        .status-3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }
        .status-2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
        }
        .status-1 {
            border: 1px solid #1c96c256;
            background-color: #1c96c256;
          
        }
        .status-0 {
            border: 1px solid #d8d8d89a;
            background-color: #d8d8d89a;
        }
</style>
